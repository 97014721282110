exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-grow-js": () => import("./../../../src/pages/grow.js" /* webpackChunkName: "component---src-pages-grow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tailormade-js": () => import("./../../../src/pages/tailormade.js" /* webpackChunkName: "component---src-pages-tailormade-js" */),
  "component---src-pages-today-tomorrow-healthtech-js": () => import("./../../../src/pages/today-tomorrow-healthtech.js" /* webpackChunkName: "component---src-pages-today-tomorrow-healthtech-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-expertise-js": () => import("./../../../src/templates/expertise.js" /* webpackChunkName: "component---src-templates-expertise-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-work-post-js": () => import("./../../../src/templates/work-post.js" /* webpackChunkName: "component---src-templates-work-post-js" */)
}

