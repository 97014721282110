import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import './src/style/style.scss'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdDxRwiAAAAABkYbOSS9DWrtyweYqFd3XWx9x1W">
      {element}
    </GoogleReCaptchaProvider>
  )
}
